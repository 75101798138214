import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Field, Form } from "react-final-form";
import { Button, Divider, Grid, Icon, Modal, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { roundedDate } from "modules/time/utils";
import { exportFormatOptions, exportInstantOptions, exportTypeOptions } from "modules/export/utils";
import { useCreateInstantExportMutation } from "modules/dashboard/dashboardService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";

import { DateTimeAdapter, DropDownAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";

const InstantExport = (props) => {
    const now = moment();
    const { setOpen } = props;
    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current);
    const selected_equipments_ids = useSelector((state) => state.dash.selected_equipments);

    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });

    const [createInstantExport, create] = useCreateInstantExportMutation();

    const success_list = [measurements.isSuccess, measurementtypes.isSuccess, dataflows.isSuccess];

    const { mtTypesOptions, selected_measurements } = useMemo(() => {
        const process = { mtTypesOptions: [], selected_measurements: [] };
        if (_.size(selected_equipments_ids) > 0 && _.every(success_list)) {
            _.each(measurements.data, (measure) => {
                //Need dataflow to check if measure in selected equipments
                const dataflow = _.find(dataflows.data, { id: measure?.dataflow });
                if (_.includes(selected_equipments_ids, dataflow?.equipment)) {
                    process.selected_measurements.push(measure);
                    if (_.find(process.mtTypesOptions, { id: measure?.measurementtype })) return; //already exists mt_type in options
                    const mt_type = _.find(measurementtypes.data, { id: measure?.measurementtype });
                    process.mtTypesOptions.push(mt_type);
                }
            });
            return process;
        }
        return process;
    }, [selected_equipments_ids, success_list, measurementtypes, measurements, dataflows]);

    const initialValues = useMemo(() => {
        return {
            start: roundedDate(now.clone().subtract(7, "days").startOf("days"), 10),
            end: roundedDate(now.clone().startOf("minute"), 10),
            export_type: _.head(exportTypeOptions).value,
            format: _.head(exportFormatOptions).value,
            instant: false,
            m_list: [],
            // Auto select measurementtype if only one
            mt_types_selected: _.size(mtTypesOptions) === 1 ? [_.head(mtTypesOptions)?.id] : []
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mtTypesOptions]);

    const submitForm = async (formData) => {
        const data = {
            ..._.omit(formData, ["mt_types_selected"]),
            m_list: _.reduce(
                selected_measurements,
                (res, measure) => {
                    if (_.includes(formData.mt_types_selected, measure?.measurementtype)) res.push(measure?.id);
                    return res;
                },
                []
            ),
            start: formData.start.format("YYYY-MM-DD HH:mm:ss"),
            end: formData.end.format("YYYY-MM-DD HH:mm:ss")
        };
        await createInstantExport({ org: org.current, data });
    };

    const validate = (values) => {
        const errors = {};
        if (values?.start === undefined) {
            errors.start = <Trans>Required field</Trans>;
            return errors;
        }
        if (typeof values?.start === "string") {
            errors.start = <Trans>Invalid format</Trans>;
            return errors;
        }
        if (values?.end === undefined) {
            errors.end = <Trans>Required field</Trans>;
            return errors;
        }
        if (typeof values?.end === "string") {
            errors.end = <Trans>Invalid format</Trans>;
            return errors;
        }
        if (_.size(values?.mt_types_selected) === 0) {
            errors.mt_types_selected = <Trans>Required field</Trans>;
            return errors;
        }
        return errors;
    };

    return (
        <Modal open centered={false} onClose={(e) => setOpen(false)}>
            <Modal.Header>
                <Trans>instant export</Trans>
            </Modal.Header>
            <Modal.Content>
                {create.isError && (
                    <MessageDisplay
                        message={i18n._(t`error csv generation`)}
                        level="error"
                        iconName="warning circle"
                        isLoading={false}
                        attached={false}
                    />
                )}
                {create.isFetching && (
                    <MessageDisplay
                        message={i18n._(t`csv generation in progress`)}
                        level="info"
                        iconName="circle notched"
                        isLoading={true}
                        attached={false}
                    />
                )}
                {create.isSuccess && (
                    <MessageDisplay
                        message={i18n._(t`csv generation success`)}
                        level="success"
                        iconName="checkmark"
                        isLoading={false}
                        attached={false}
                    />
                )}
                <Form
                    onSubmit={submitForm}
                    keepDirtyOnReinitialize
                    initialValues={initialValues}
                    validate={validate}
                    render={({ form, handleSubmit, submitting, pristine, invalid, values }) => {
                        let endBeforeStart = false;
                        if (moment.isMoment(values.start) && moment.isMoment(values.end) && values.end.isBefore(values.start)) {
                            endBeforeStart = true;
                        }
                        let hasTimeIntegral = false;
                        _.each(values?.mt_types_selected, (mt_type_id) => {
                            const mt_type = _.find(mtTypesOptions, { id: mt_type_id });
                            if (mt_type && mt_type?.datapoint_type === 3 && hasTimeIntegral === false) hasTimeIntegral = true;
                        });

                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid stackable centered>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <div className="field required">
                                                <label>
                                                    <Trans>date range</Trans>
                                                </label>
                                            </div>
                                        </Grid.Column>
                                        {endBeforeStart && (
                                            <Grid.Column width={16}>
                                                <MessageDisplay
                                                    message={i18n._(t`end after start`)}
                                                    level="error"
                                                    iconName="warning circle"
                                                    isLoading={false}
                                                    attached={false}
                                                />
                                            </Grid.Column>
                                        )}
                                        <Grid.Column width={8}>
                                            {/* DATEPICKER start */}
                                            <Field
                                                name="start"
                                                component={DateTimeAdapter}
                                                locale={current_lng}
                                                date_limit={now}
                                                labeled={true}
                                                label={i18n._(t`from`)}
                                                labelPosition={"left"}
                                                isRequired={true}
                                                dateFormat={true}
                                                timeFormat={true}
                                            />
                                            {/* END DATEPICKER start */}
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            {/* DATEPICKER end */}
                                            <Field
                                                name="end"
                                                component={DateTimeAdapter}
                                                locale={current_lng}
                                                date_limit={now}
                                                labeled={true}
                                                label={i18n._(t`to`)}
                                                labelPosition={"left"}
                                                isRequired={true}
                                                dateFormat={true}
                                                timeFormat={true}
                                            />
                                            {/* END DATEPICKER end */}
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <em>
                                                <Trans>instantexport time helper</Trans>
                                            </em>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Field
                                                name="export_type"
                                                label={i18n._(t`export_type`)}
                                                placeholder={""}
                                                options={exportTypeOptions}
                                                component={DropDownAdapter}
                                                isRequired={true}
                                                customAction={(data) => {
                                                    if (data !== 1) form.change("instant", false);
                                                }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Field
                                                name="format"
                                                label={i18n._(t`export_format`)}
                                                placeholder={""}
                                                options={exportFormatOptions}
                                                component={DropDownAdapter}
                                                isRequired={true}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Field
                                                name="mt_types_selected"
                                                label={i18n._(t`measurementtypes`)}
                                                placeholder={i18n._(t`Select measurementtypes`)}
                                                options={mtTypesOptions}
                                                multipleselect={1}
                                                isRequired={true}
                                                component={DropDownAdapter}
                                                noResultsMessage={i18n._(t`Measurement type not found`)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    {values?.export_type === 1 && hasTimeIntegral && (
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="instant"
                                                    label={i18n._(t`data type`)}
                                                    placeholder={""}
                                                    options={exportInstantOptions}
                                                    component={DropDownAdapter}
                                                    isRequired={true}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                </Grid>
                                <Divider />
                                <Segment basic textAlign="right">
                                    <Button type="button" negative onClick={() => setOpen(false)}>
                                        <Trans>cancel</Trans>
                                    </Button>
                                    <Button
                                        type="button"
                                        labelPosition="right"
                                        icon
                                        positive
                                        onClick={async (e) => {
                                            form.submit();
                                        }}
                                        disabled={submitting || invalid || endBeforeStart || create.isFetching}
                                    >
                                        <Trans>validate</Trans>
                                        <Icon name="send" inverted />
                                    </Button>
                                </Segment>
                            </form>
                        );
                    }}
                />
            </Modal.Content>
        </Modal>
    );
};

export default InstantExport;
