import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";
import cookie from "react-cookies";
import moment from "moment";

export const equipmentApi = defaultApi.injectEndpoints({
    reducerPath: "apiEquipment",
    endpoints: (build) => ({
        getEquipments: build.query({
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `equipments?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 120,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "Equipments", id: arg.org.id }];
                return [];
            },
            transformResponse: (response) => {
                return _.map(response, (equipment) => {
                    return { ...equipment, key: equipment.id, text: equipment.name, value: equipment.id };
                });
            }
        }),
        getEquipment: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return `equipments/${data}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                return [{ type: "Equipment", id: arg.data }];
            }
        }),
        getEquipmentEfficiency: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return `efficiencies/${data}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                return [{ type: "EquipmentEfficiency", id: arg.data }];
            }
        }),
        createEquipment: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `equipments?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "Machines" },
                        { type: "Equipments" }, //refresh equipments list
                        { type: "SortedDashboards" },
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" }, //refresh measurements list
                        { type: "Tag" },
                        { type: "Equipment" } //refresh specific equipment
                    ];
                }
                return [];
            }
        }),
        updateEquipment: build.mutation({
            query: ({ org, equipment_id, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `equipments/${equipment_id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "Machines" },
                        { type: "Equipments" }, //refresh equipments list
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" }, //refresh measurements list
                        { type: "Components" },
                        { type: "Equipment", id: arg.equipment_id } //refresh specific equipment
                    ];
                }
                return [];
            }
        }),
        patchEquipment: build.mutation({
            query: ({ org, equipment_id, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `equipments/${equipment_id}?org=${current_org}`,
                    method: "PATCH",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "Machines" },
                        { type: "Equipments" }, //refresh equipments list
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" }, //refresh measurements list
                        { type: "Components" },
                        { type: "Equipment", id: arg.equipment_id } //refresh specific equipment
                    ];
                }
                return [];
            }
        }),
        deleteEquipment: build.mutation({
            query: ({ org, data, confirm }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `equipments/${data}?org=${current_org}${confirm ? "&confirm=yes" : ""}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error && arg.confirm) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "Equipments", id: arg.org.id },
                        { type: "SortedDashboards", id: arg.org.id },
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" } //refresh measurements list
                    ];
                }
                return [];
            }
        }),
        getEquipmentMeasurements: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return `equipments/${data}/measurements?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                return [{ type: "EquipmentMeasurements", id: arg.data }];
            },
            transformResponse: (response, meta, arg) => {
                return response;
            }
        }),
        createEquipmentMeasurement: build.mutation({
            query: ({ org, data, equipment_id }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `equipments/${equipment_id}/measurements?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "EquipmentMeasurements", id: arg.equipment_id },
                        { type: "Equipments", id: arg.org.id },
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" } //refresh measurements list
                    ];
                }
                return [];
            }
        }),
        updateEquipmentMeasurement: build.mutation({
            query: ({ org, data, equipment_id, measurement_id }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `equipments/${equipment_id}/measurements/${measurement_id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "EquipmentMeasurements", id: arg.equipment_id },
                        { type: "Equipments", id: arg.org.id },
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" } //refresh measurements list
                    ];
                }
                return [];
            }
        }),
        deleteEquipmentMeasurement: build.mutation({
            query: ({ org, equipment_id, measurement_id, confirm }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `measurements/${measurement_id}?org=${current_org}${confirm ? "&confirm=yes" : ""}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error && arg.confirm) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "EquipmentMeasurements", id: arg.equipment_id },
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" } //refresh measurements list
                    ];
                }
                return [];
            }
        }),
        detachDataflow: build.mutation({
            query: ({ org, dataflow_id, transfer_type_str, data, confirm }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `dataflows/${dataflow_id}/${transfer_type_str}?org=${current_org}${
                        confirm ? "&confirm=yes" : ""
                    }&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error && arg.confirm) {
                    return [
                        // Maybe prevent refresh request for deleted equipment if no dataflow after detach
                        { type: "EquipmentMeasurements", id: arg.equipment_id },
                        { type: "Equipments", id: arg.org.id },
                        { type: "SortedDashboards", id: arg.org.id },
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" } //refresh measurements list
                    ];
                }
                return [];
            }
        }),
        createEfficiency: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `efficiencies?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "Equipment", id: arg?.data?.equipment }, //refresh specific equipment,
                        { type: "EquipmentMeasurements", id: arg?.data?.equipment },
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" } //refresh measurements list
                    ];
                }
                return [];
            }
        }),
        updateEfficiency: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `efficiencies/${data?.id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "Equipment", id: arg?.data?.equipment }, //refresh specific equipment,
                        { type: "EquipmentMeasurements", id: arg?.data?.equipment },
                        { type: "EquipmentEfficiency", id: arg.data?.id },
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" } //refresh measurements list
                    ];
                }
                return [];
            }
        }),
        deleteEfficiency: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `efficiencies/${data}?org=${current_org}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error && arg.confirm) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return [
                        { type: "Equipment", id: arg?.equipment_id },
                        { type: "EquipmentMeasurements", id: arg?.equipment_id },
                        { type: "Dataflows" }, //refresh dataflows list
                        { type: "Measurements" } //refresh measurements list
                    ];
                }
                return [];
            }
        })
    }),
    overrideExisting: false
});

export const {
    useGetEquipmentsQuery,
    useGetEquipmentQuery,
    useCreateEquipmentMutation,
    useUpdateEquipmentMutation,
    usePatchEquipmentMutation,
    useDeleteEquipmentMutation,
    useGetEquipmentEfficiencyQuery,
    useGetEquipmentMeasurementsQuery,
    useCreateEquipmentMeasurementMutation,
    useUpdateEquipmentMeasurementMutation,
    useDetachDataflowMutation,
    useCreateEfficiencyMutation,
    useUpdateEfficiencyMutation,
    useDeleteEfficiencyMutation,
    useDeleteEquipmentMeasurementMutation
} = equipmentApi;
