import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Segment, Grid, Header, Button, Input, Dropdown, Label, Popup } from "semantic-ui-react";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import { removeAccents } from "modules/common/utils";

import {
    resetFilterWithPage,
    setItemsPerPage,
    setPage,
    setSearchIdentifierFilter,
    setSensorTypeFilter,
    setSiteFilter,
    setStickPage
} from "modules/sensor/sensorSlice";

import i18n from "modules/i18n/i18nConfig";
import Back from "modules/common/components/back";
import TableEnhanced from "modules/common/components/TableEnhanced";
import MessageDisplay from "modules/common/components/MessageDisplay";
import history_app from "history_app";
import DeleteSensor from "./DeleteSensor";
import { checkRights } from "modules/auth/utils";

const Sensors = (props) => {
    const dispatch = useDispatch();
    const now = moment();
    const [ready, setReady] = useState(false);
    const [deleteSensor, setDeleteSensor] = useState(null);
    const [searchIdentifierLocal, setSearchIdentifierLocal] = useState("");
    const { sensor, sensortype, auth, notification } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const theme = useSelector((state) => state.common.theme);

    useEffect(() => {
        (async () => {
            await setSearchIdentifierLocal(sensor.filter.searchIdentifier);
            await dispatch(setSearchIdentifierFilter(sensor.filter.searchIdentifier));
            await dispatch(setSiteFilter(sensor.filter.siteFilter));
            await dispatch(setSensorTypeFilter(sensor.filter.sensorTypeFilter));

            //Change stick page after filter application.
            sensor.pagination.stickPage && (await dispatch(setStickPage(false)));
            setReady(true);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!ready) {
        return <MessageDisplay message={i18n._(t`loading filter`)} level="info" iconName="circle notched" isLoading={true} />;
    }

    const headCells = [
        {
            id: "id",
            label: "id",
            textAlign: "right"
        },
        {
            id: "identifier",
            label: i18n._(t`identifier`),
            textAlign: "left"
        },
        {
            id: "sensor_type",
            label: i18n._(t`sensor_type`),
            textAlign: "left"
        },
        {
            id: "site",
            label: i18n._(t`site`),
            textAlign: "left"
        },
        {
            id: "last_seen_tmst",
            label: i18n._(t`last_seen_tmst`),
            textAlign: "left",
            collapsing: true
        },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }
    ];

    const renderLastSeen = (date) => {
        let color = "red";
        if (date === null) {
            return "-";
        }
        const diff = now.diff(moment(date), "hours");
        if (diff <= 3) {
            color = "green";
        } else if (diff <= 24) {
            color = "orange";
        }
        return <span style={{ cursor: "default", whiteSpace: "nowrap", color }}>{moment(date).locale(current_lng).format("LLL")}</span>;
    };

    const sensors_list = _.chain(sensor.sensors)
        .reduce((res, item, idx) => {
            const rights = checkRights(item, auth.rights);
            const can_change = _.includes(rights, "can_change");

            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                identifier: {
                    render: _.get(item, "identifier", "-"),
                    value: _.get(item, "identifier", null),
                    textAlign: "left",
                    datatype: "string"
                },
                sensor_type: {
                    render: _.chain(sensortype.sensortypes).find({ name: item.sensor_type_name }).get("text").value() || "-",
                    value: _.chain(sensortype.sensortypes).find({ name: item.sensor_type_name }).get("text").value() || null,
                    textAlign: "left",
                    datatype: "string"
                },
                site: {
                    render: _.chain(props.sites).find({ id: item.site }).get("name").value() || "-",
                    value: _.chain(props.sites).find({ id: item.site }).get("name").value() || null,
                    textAlign: "left",
                    datatype: "string"
                },
                last_seen_tmst: {
                    render: renderLastSeen(item.last_seen_tmst),
                    value: item.last_seen_tmst !== null ? moment(item.last_seen_tmst) : null,
                    textAlign: "right",
                    datatype: "date"
                },
                actions: {
                    render: (() => (
                        <Button.Group>
                            <Popup
                                trigger={
                                    <Button
                                        icon={notification.srv_status.db_status === "rw" && can_change ? "edit" : "eye"}
                                        floated="left"
                                        size="mini"
                                        onClick={async (e) => {
                                            await dispatch(setStickPage(true));
                                            history_app.push(`sensors/${item.id}`);
                                        }}
                                    />
                                }
                            >
                                <Popup.Content>
                                    {can_change && <Trans>Sensor edition</Trans>}
                                    {!can_change && <Trans>View sensor</Trans>}
                                </Popup.Content>
                            </Popup>
                            {notification.srv_status.db_status === "rw" && _.includes(rights, "can_delete") && (
                                <Popup
                                    trigger={
                                        <Button
                                            icon="delete"
                                            onClick={(e) => {
                                                setDeleteSensor(item);
                                            }}
                                        />
                                    }
                                >
                                    <Popup.Content>
                                        <Trans>deletion of sensor</Trans>
                                    </Popup.Content>
                                </Popup>
                            )}
                        </Button.Group>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back
                            action={async () => {
                                await dispatch(resetFilterWithPage());
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>sensors management</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <Grid celled>
                            <Grid.Column mobile={16} tablet={3} computer={3}>
                                <Input
                                    fluid
                                    icon="search"
                                    placeholder={i18n._(t`search identifier`)}
                                    onChange={async (e, { value }) => {
                                        const search = value.trim();
                                        await setSearchIdentifierLocal(value); //display raw string in input field
                                        await dispatch(setSearchIdentifierFilter(search)); //Used for list filtering
                                    }}
                                    value={searchIdentifierLocal}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={3} computer={3}>
                                <Dropdown
                                    fluid
                                    options={_.chain(props.sites)
                                        .map(({ key, text, value }) => ({
                                            key,
                                            text,
                                            value,
                                            content: <Label color={theme === "old" ? "blue" : "olive"}>{i18n._(text)}</Label>
                                        }))
                                        .orderBy((item) => {
                                            return removeAccents(item.text).toLowerCase();
                                        }, "asc")
                                        .value()}
                                    placeholder={i18n._(t`select sites`)}
                                    multiple
                                    selection
                                    search
                                    onChange={(e, { value }) => dispatch(setSiteFilter(value))}
                                    renderLabel={(label) => ({ color: theme === "old" ? "blue" : "olive", content: i18n._(label.text) })}
                                    value={sensor.filter.siteFilter}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={3} computer={3}>
                                <Dropdown
                                    fluid
                                    options={_.chain(sensortype.sensortypes)
                                        .map(({ key, text, value }) => ({ key, text, value, content: <Label color="purple">{i18n._(text)}</Label> }))
                                        .orderBy((item) => {
                                            return removeAccents(item.text).toLowerCase();
                                        }, "asc")
                                        .value()}
                                    placeholder={i18n._(t`select sensortypes`)}
                                    multiple
                                    selection
                                    search
                                    onChange={(e, { value }) => dispatch(setSensorTypeFilter(value))}
                                    renderLabel={(label) => ({ color: "purple", content: i18n._(label.text) })}
                                    value={sensor.filter.sensorTypeFilter}
                                />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <TableEnhanced
                                    headCells={headCells}
                                    rows={sensors_list}
                                    textItemPerPages={i18n._(t`items per page`)}
                                    orderBy="last_seen_tmst"
                                    order="desc"
                                    page={sensor.pagination.page}
                                    rowsPerPage={sensor.pagination.itemsPerPage}
                                    setPage={(page) => {
                                        dispatch(setPage(page));
                                    }}
                                    setItemsPerPage={(items) => {
                                        dispatch(setItemsPerPage(items));
                                    }}
                                />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {deleteSensor !== null && <DeleteSensor item={deleteSensor} setDeleteSensor={setDeleteSensor} />}
        </Segment>
    );
};

export default Sensors;
