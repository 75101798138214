import React, { useState } from "react";
import _ from "lodash";
import { Button, Icon, Menu, Popup } from "semantic-ui-react";
import { faRuler } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "@lingui/macro";
import i18n from "modules/i18n/i18nConfig";
import { useSelector } from "react-redux";
import { co2ConversionRate, priceConversionRate } from "modules/common/utils";

export const convertOptions = [
    { key: 0, value: 0, icon: "area graph", text: t`default display` },
    { key: 1, value: 1, icon: "currency", text: t`currency display` },
    { key: 2, value: 2, icon: "cloud", text: t`co2 display` }
];

export const ConsumptionsButton = (props) => {
    const { series, setSeries, measure } = props;
    const { dataflow } = useSelector((state) => state);
    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(0);

    const wrap_df = _.chain(dataflow).get("dataflows", []).find({ id: measure.dataflow });
    const site_conversions = wrap_df.get("site.conversions", null).value();
    const category_name = wrap_df.get("dataflowspec.name").value();
    const mt_type_name = _.get(measure, "measurementtype.name");

    const changeDisplay = (e, data) => {
        setDisplay(data.value);
        if (data.value === 0) {
            setSeries(series);
        } else if (data.value === 1) {
            const transformSeries = _.chain(series)
                .map((serie) => {
                    const transformData = _.chain(serie)
                        .get("data", [])
                        .map((record) => {
                            return {
                                ...record,
                                y: priceConversionRate(record.y / record.factor, site_conversions, category_name, mt_type_name, i18n, false),
                                y_real: priceConversionRate(
                                    record.y_real / record.factor,
                                    site_conversions,
                                    category_name,
                                    mt_type_name,
                                    i18n,
                                    false
                                ),
                                unit: _.get(site_conversions, "currency", "-")
                            };
                        })
                        .value();

                    return {
                        ...serie,
                        title: `${i18n._(serie.name)} (${_.get(site_conversions, "currency", "-")})`,
                        data: transformData,
                        unit: _.get(site_conversions, "currency", "-")
                    };
                })
                .value();
            setSeries(transformSeries);
        } else if (data.value === 2) {
            const transformSeries = _.chain(series)
                .map((serie) => {
                    const transformData = _.chain(serie)
                        .get("data", [])
                        .map((record) => {
                            return {
                                ...record,
                                y: co2ConversionRate(record.y / record.factor, site_conversions, category_name, mt_type_name, i18n, false),
                                y_real: co2ConversionRate(record.y_real / record.factor, site_conversions, category_name, mt_type_name, i18n, false),
                                unit: "kgCo2e"
                            };
                        })
                        .value();
                    return {
                        ...serie,
                        data: transformData,
                        title: `${i18n._(serie.name)} (kgCo2e)`,
                        unit: "kgCo2e"
                    };
                })
                .value();
            setSeries(transformSeries);
        }
    };

    return (
        <Popup
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            flowing
            hoverable
            trigger={
                <Button icon className="no-print">
                    <FontAwesomeIcon icon={faRuler} className="icon" />
                </Button>
            }
            style={{ padding: 0 }}
        >
            <Popup.Content>
                <Menu vertical compact fluid>
                    {_.map(convertOptions, (option) => {
                        return (
                            <Menu.Item key={option.value} value={option.value} active={display === option.value} onClick={changeDisplay}>
                                <Icon name={option.icon} />
                                {i18n._(option.text)}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            </Popup.Content>
        </Popup>
    );
};

export default ConsumptionsButton;
