import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Button, Grid, Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetEquipmentMeasurementsQuery, useGetEquipmentsQuery } from "../equipmentService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetSensortypesQuery } from "modules/sensor/sensorTypeService";
import { useGetCategoriesQuery } from "modules/category/categoryService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import MeasurementForm from "modules/equipment/components/form/MeasurementForm";
import DetachModal from "modules/equipment/components/modal/DetachModal";
import AddMeasurementModal from "modules/equipment/components/modal/AddMeasurementModal";
import AddEfficiencyModal from "./modal/AddEfficiencyModal";
import DeleteEquipmentMeasurement from "./DeleteEquipmentMeasurement";

const PaneMeasurements = (props) => {
    const { equipment } = props;
    const org = useSelector((state) => state.org);
    const notification = useSelector((state) => state.notification);
    const [openAddMeasure, setOpenAdd] = useState(false);
    const [openAddEfficiency, setOpenAddEfficiency] = useState(false);
    const [openDetachMeasure, setOpenDetach] = useState(null);
    const [openDeleteMeasurement, setOpenDeleteMeasurement] = useState(null);

    useSelector((state) => state.i18n.current); //force refresh for lng

    const equipmentMeasurements = useGetEquipmentMeasurementsQuery(
        { org: org.current, data: equipment?.id },
        { skip: !org.current || equipment?.id === undefined }
    );
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const sensortypes = useGetSensortypesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });

    const measurementsObject = useMemo(() => {
        if (measurements.isSuccess && dataflows.isSuccess && measurementtypes.isSuccess && equipments.isSuccess) {
            return _.reduce(
                measurements.data,
                (res, measure) => {
                    const df = _.find(dataflows.data, { id: measure?.dataflow });
                    const mttype = _.find(measurementtypes.data, { id: measure?.measurementtype });
                    const eqt = _.find(equipments.data, { id: df?.equipment });
                    const representation = df && mttype ? `${df?.name} - ${mttype.repr}` : "-";
                    const new_measure = { key: measure?.id, text: representation, value: String(measure?.id) };
                    res[measure.id] = { ...measure, ...new_measure, dataflow: df, measurementtype: mttype, equipment: eqt };
                    return res;
                },
                {}
            );
        }
        return {};
    }, [measurements, dataflows, measurementtypes, equipments]);

    const { measures = [], can_add_efficiency = false } = useMemo(() => {
        if (equipmentMeasurements.isSuccess && measurementtypes.isSuccess && sensortypes.isSuccess && categories.isSuccess) {
            //use to prevent detach dataflow
            const df_counter = _.chain(equipmentMeasurements.data)
                .map((measure) => {
                    return measure.dataflow;
                })
                .uniq()
                .size()
                .value();

            let measures_index_counter = 0;

            const measures = _.chain(equipmentMeasurements.data)
                .map((measurement, m_id) => {
                    const mt_type = _.find(measurementtypes.data, { id: measurement?.measurementtype });
                    const sensor_type = _.find(sensortypes.data, { id: measurement?.sensor?.sensor_type });
                    const { connector = null, channel = null, id = null } = measurement?.tech_info ?? {};

                    if (mt_type?.datapoint_type === 3) {
                        measures_index_counter += 1;
                    }

                    let extra_sensor = "";
                    if (sensor_type !== undefined) {
                        extra_sensor = `- ${i18n._(t`sensor`)} ${sensor_type?.text ?? ""} ${measurement?.sensor?.identifier ?? ""}`;
                    }
                    let extra_field = "";
                    if (_.includes(["PE6-Extended", "Power-Elec-6"], sensor_type?.name)) {
                        if (_.isFinite(connector)) {
                            extra_field += `- ${i18n._(t`connector`)} ${connector}`;
                        }
                        if (_.isFinite(channel) && channel > 0) {
                            extra_field += ` ${i18n._(t`channel`)} ${channel}`;
                        }
                    } else if (_.includes(["PowerModbus"], sensor_type?.name)) {
                        if (id !== null) {
                            const v1 = /^\d+_\d+$/;
                            const v2 = /^(modbus|opc)=\d+;ns=\d+;(nr|i)=\d+$/;

                            if (v1.test(id)) {
                                const paramsArray = _.split(id, "_");
                                const slave = paramsArray?.[0];
                                const register = paramsArray?.[1];
                                if (slave) {
                                    extra_field += `- ${i18n._(t`Slave ID`)}: ${slave}`;
                                }
                                if (register) {
                                    extra_field += `- ${i18n._(t`Register`)}: ${register}`;
                                }
                            } else if (v2.test(id)) {
                                const paramsArray = _.split(id, ";");
                                const parsedParams = _.reduce(
                                    paramsArray,
                                    (result, param) => {
                                        const [key, value] = _.split(param, "=");
                                        result[key] = value;
                                        return result;
                                    },
                                    {}
                                );
                                if (_.has(parsedParams, "modbus")) {
                                    if (parsedParams?.["ns"]) {
                                        extra_field += `- ${i18n._(t`Slave ID`)}: ${parsedParams?.["ns"]}`;
                                    }
                                    if (parsedParams?.["nr"]) {
                                        extra_field += `- ${i18n._(t`Register`)}: ${parsedParams?.["nr"]}`;
                                    }
                                } else if (_.has(parsedParams, "opc")) {
                                    if (parsedParams?.["ns"] && parsedParams?.["i"]) {
                                        extra_field += `- ${i18n._(t`NodeID`)}: ns=${parsedParams?.["ns"]};i=${parsedParams?.["i"]}`;
                                    }
                                }
                            }
                        }
                    } else {
                        if (_.isFinite(id)) {
                            extra_field += `- ${i18n._(t`channel`)} ${id}`;
                        }
                    }

                    const dataflowspec_tech = _.find(categories.data, { id: measurement?.dataflowspec_tech });
                    const dataflowspec = _.find(categories.data, { id: measurement?.dataflowspec });

                    const can_detach =
                        notification.srv_status.db_status === "rw" &&
                        df_counter >= 1 &&
                        //Prevent detach elec_predict, battery and battery_status
                        !_.includes(["elec_predict", "battery", "battery_status"], dataflowspec_tech?.name) &&
                        //Detach pulse and analog after setup first
                        !_.includes(["pulse", "analog"], dataflowspec?.name);

                    const can_delete = notification.srv_status.db_status === "rw" && (measurement?.syndataflow ?? null) !== null && df_counter >= 2;

                    return (
                        <Grid.Column key={m_id} mobile={16} tablet={14} computer={14}>
                            <Header
                                as="h3"
                                attached="top"
                                block
                                textAlign="right"
                                id="pwaEquipmentMeasurementHeader"
                                style={{ display: "flex", flexWrap: "wrap", alignItems: "center", padding: "0.3rem", minHeight: "45px" }}
                            >
                                <div style={{ flex: 1 }} />
                                <div style={{ flex: 2, textAlign: "center" }}>
                                    <h4>{`${i18n._(dataflowspec?.text ?? "")} - ${i18n._(mt_type?.text ?? "")} ${extra_sensor} ${extra_field}`}</h4>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Button.Group>
                                        {can_detach && (
                                            <Button
                                                icon="arrows alternate horizontal"
                                                onClick={(e) => {
                                                    setOpenDetach(measurement);
                                                }}
                                            />
                                        )}
                                        {can_delete && (
                                            <Button
                                                icon="delete"
                                                onClick={(e) => {
                                                    setOpenDeleteMeasurement(measurement);
                                                }}
                                            />
                                        )}
                                    </Button.Group>
                                </div>
                            </Header>
                            <MeasurementForm measurement={measurement} equipment={equipment} measurementsObject={measurementsObject} />
                        </Grid.Column>
                    );
                })
                .value();

            const can_add_efficiency = equipment?.efficiency === null && measures_index_counter >= 2;
            return { measures, can_add_efficiency };
        }
        return { measures: [], can_add_efficiency: false };
    }, [equipmentMeasurements, measurementtypes, sensortypes, categories, measurementsObject, equipment, notification]);

    if (equipmentMeasurements.isFetching) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (equipmentMeasurements.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (equipmentMeasurements.isSuccess) {
        return (
            <>
                <Segment attached="top">
                    <Button onClick={(e) => setOpenAdd(true)}>
                        <Trans>add calculated measure</Trans>
                    </Button>
                    {can_add_efficiency && (
                        <Button onClick={(e) => setOpenAddEfficiency(true)}>
                            <Trans>Add efficiency</Trans>
                        </Button>
                    )}
                </Segment>
                <Segment attached>
                    <Grid centered>{measures}</Grid>
                </Segment>
                {openDetachMeasure !== null && <DetachModal measurement={openDetachMeasure} setOpen={setOpenDetach} equipment={equipment} />}
                {openAddMeasure && <AddMeasurementModal equipment={equipment} setOpen={setOpenAdd} />}
                {openAddEfficiency && (
                    <AddEfficiencyModal
                        equipment={equipment}
                        setOpen={setOpenAddEfficiency}
                        measurements={equipmentMeasurements.data}
                        measurementsObject={measurementsObject}
                    />
                )}
                {openDeleteMeasurement !== null && (
                    <DeleteEquipmentMeasurement measurement={openDeleteMeasurement} setOpen={setOpenDeleteMeasurement} equipment={equipment} />
                )}
            </>
        );
    }
    return null;
};

export default React.memo(PaneMeasurements);
