import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Button, Divider, Header, Segment, Step } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { formulaTypeEnum, main_default_equipment, syndataflowtypeEnum } from "modules/equipment/utils";
import { isPlainObject, reparseNumber } from "modules/common/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useCreateEquipmentMutation, useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import EquipmentMainFields from "./EquipmentMainFields";
import MeasurementFields from "./MeasurementFields";
import { default_sum_formula } from "../advanced/SyntheticFormulaAdapter";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const EquipmentAddForm = (props) => {
    const { setOpen } = props; //needed to close Modal or history.push to new equipment after creation?
    const [active, setActive] = useState("Equipment");

    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const theme = useSelector((state) => state.common.theme);
    const notification = useSelector((state) => state.notification);
    useSelector((state) => state.i18n.current); //force refresh for lng

    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });

    const measurementsObject = useMemo(() => {
        if (measurements.isSuccess && dataflows.isSuccess && measurementtypes.isSuccess && equipments.isSuccess) {
            return _.reduce(
                measurements.data,
                (res, measure) => {
                    const df = _.find(dataflows.data, { id: measure?.dataflow });
                    const mttype = _.find(measurementtypes.data, { id: measure?.measurementtype });
                    const eqt = _.find(equipments.data, { id: df?.equipment });
                    const representation = df && mttype ? `${df?.name} - ${mttype.repr}` : "-";
                    const new_measure = { key: measure?.id, text: representation, value: String(measure?.id) };
                    res[measure.id] = { ...measure, ...new_measure, dataflow: df, measurementtype: mttype, equipment: eqt };
                    return res;
                },
                {}
            );
        }
        return {};
    }, [measurements, dataflows, measurementtypes, equipments]);

    const [createEquipment, create] = useCreateEquipmentMutation();

    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create equipment`), toast_options);
            setOpen(false);
        }
        if (create.isError) {
            let error = i18n._(t`cannot create equipment`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create, setOpen]);

    const initialValues = useMemo(() => {
        return {
            equipment: main_default_equipment,
            dataflow: {
                name: "",
                dataflowspec: null,
                standby_threshold: null
            },
            syndataflow: {
                formula: default_sum_formula,
                syndataflowtype: syndataflowtypeEnum.DERIVATIVEPOSITIVE,
                formula_type: formulaTypeEnum.SUMDIFFERENCE
            },
            measurement: {
                display_unit: -1,
                measurementtype: null,
                factor: 1,
                offset: 0,
                minGauge: 0,
                maxGauge: 1000
            }
        };
    }, []);

    const can_change = useMemo(() => {
        return _.size(auth.rights?.sites_rw) > 0;
    }, [auth.rights]);

    const submitForm = (formData) => {
        if (can_change && notification.srv_status.db_status === "rw") {
            const data = _.mapValues(formData, (item, key) => {
                if (key === "measurement") {
                    return _.mapValues(item, (field, field_key) => {
                        if (field_key === "display_unit" && field === -1) {
                            return null;
                        }
                        if (_.includes(["offset", "factor", "minGauge", "maxGauge"], field_key)) {
                            return reparseNumber(field);
                        }
                        return field;
                    });
                }
                if (key === "dataflow") {
                    return _.mapValues(item, (field, field_key) => {
                        if (field_key === "standby_threshold") {
                            return reparseNumber(field);
                        }
                        return field;
                    });
                }
                if (key === "syndataflow") {
                    const syndataflow = _.mapValues(item, (field, field_key, all_fields) => {
                        if (field_key === "formula") {
                            if (isPlainObject(field)) {
                                return _.mapValues(field, (formula_field, formula_field_key) => {
                                    if (_.includes(["m_a", "m_b", "m_c", "f_a"], formula_field)) {
                                        return reparseNumber(field);
                                    }
                                    return formula_field;
                                });
                            }
                            if (Array.isArray(field)) {
                                return _.map(field, (line_formula) => {
                                    if (line_formula?.type === "factor") {
                                        return {
                                            ...line_formula,
                                            measure: reparseNumber(line_formula.measure),
                                            factor: reparseNumber(line_formula.factor)
                                        };
                                    }
                                    return { ...line_formula, measure: reparseNumber(line_formula.measure) };
                                });
                            }
                        }
                        if (field_key === "syndataflowtype") {
                            //update syndataflowtype based on formula_type
                            return (
                                {
                                    2: syndataflowtypeEnum.HEATENERGY,
                                    3: syndataflowtypeEnum.GENERICPRODUCT
                                }[all_fields?.formula_type] ?? field
                            );
                        }
                        return field;
                    });
                    return _.omit(syndataflow, ["formula_type"]);
                }
                return item;
            });
            createEquipment({ org: org.current, data });
        }
    };

    return (
        <>
            <Step.Group stackable="tablet" fluid>
                <Step
                    active={active === "Equipment"}
                    id="Equipment"
                    onClick={(e, data) => {
                        setActive(data.id);
                    }}
                    title={i18n._(t`Equipment definition`)}
                    description=""
                    className={theme === "old" ? "" : "pwaSchaefflerStep"}
                />
                <Step
                    active={active === "Syndataflow"}
                    id="Syndataflow"
                    onClick={(e, data) => {
                        setActive(data.id);
                    }}
                    title={i18n._(t`Measurement definition`)}
                    description=""
                    className={theme === "old" ? "" : "pwaSchaefflerStep"}
                />
            </Step.Group>
            <Form
                onSubmit={submitForm}
                initialValues={initialValues}
                render={({ handleSubmit, form, submitting, pristine, invalid, values, errors }) => {
                    return (
                        <form onSubmit={handleSubmit} className="ui form">
                            <div style={{ display: active === "Equipment" ? "block" : "none" }}>
                                <Header as="h3" attached="top" block textAlign="center">
                                    <Trans>Equipment definition</Trans>
                                </Header>
                                <Segment attached>
                                    <EquipmentMainFields form={form} values={values} can_change={can_change} />
                                </Segment>
                            </div>
                            <div style={{ display: active === "Syndataflow" ? "block" : "none" }}>
                                <Header as="h3" attached="top" block textAlign="center">
                                    <Trans>Measurement definition</Trans>
                                </Header>
                                <Segment attached>
                                    <MeasurementFields
                                        form={form}
                                        values={values}
                                        isSynthetic={true}
                                        isPulse={false}
                                        measurementsObject={measurementsObject}
                                    />
                                </Segment>
                            </div>
                            {can_change && notification.srv_status.db_status === "rw" && (
                                <>
                                    <Divider />
                                    <Segment basic textAlign="right">
                                        <Button type="button" negative onClick={(e) => setOpen(false)}>
                                            <Trans>cancel</Trans>
                                        </Button>
                                        {active === "Equipment" && (
                                            <Button type="button" content={i18n._(t`next`)} onClick={() => setActive("Syndataflow")} />
                                        )}
                                        {active === "Syndataflow" && (
                                            <>
                                                <Button type="button" content={i18n._(t`previous`)} onClick={() => setActive("Equipment")} />
                                                <Button
                                                    type="submit"
                                                    positive
                                                    content={i18n._(t`validate`)}
                                                    disabled={submitting || pristine || invalid}
                                                />
                                            </>
                                        )}
                                    </Segment>
                                </>
                            )}
                        </form>
                    );
                }}
            />
        </>
    );
};

export default React.memo(EquipmentAddForm);
