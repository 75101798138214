import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { t, Trans } from "@lingui/macro";
import { Form, Field } from "react-final-form";
import _ from "lodash";
import { toast } from "react-toastify";
import { Grid, Header, Segment, Container, Divider, Button, Accordion, Icon } from "semantic-ui-react";

import history_app from "history_app";
import i18n from "modules/i18n/i18nConfig";
import { timeZone_list, validateNumber } from "modules/common/utils";
import { dropdownOptions, priceCurrency } from "../utils/index";
import { checkRights } from "modules/auth/utils";
import { identity, identityNull } from "modules/common/utils/form";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useGetSitesQuery, useGetStationsQuery, useCreateSiteMutation, useUpdateSiteMutation, useGetSiteQuery } from "../siteService";

import { InputAdapter, TextAreaAdapter, DropDownAdapter } from "modules/common/components/form";
import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import Areas from "modules/area/components/Areas";
import SiteCalendar from "./SiteCalendar";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const Site = () => {
    const { id_site, mode } = useParams();

    const [activeIndex, setActiveIndex] = useState(-1); // show dropdown for conversion table

    const { org, auth, notification } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const siteStations = useGetStationsQuery({ org: org.current }, { skip: !org.current });
    const site = useGetSiteQuery({ org: org.current, data: id_site }, { skip: !org.current || id_site === undefined });

    const org_id = _.get(org, "current.id", null);
    const user_id = _.get(auth, "user.user_id", null);

    const default_site = {
        name: "",
        external_id: "",
        description: "",
        address: "",
        timezone: -1,
        station: -1,
        conversions: {
            currency: "",
            price_gas: null,
            price_gas_m3: null,
            price_elec: null,
            price_water: null,
            co2_elec: null,
            co2_m3: null,
            co2_nm3: null,
            co2_gas: null,
            co2_water: null
        },
        organization: org_id,
        owner: user_id
    };

    let err_list = [sites.isError, siteStations.isError];
    let status_list = [sites.isSuccess, siteStations.isSuccess];

    const { data: stations_list } = siteStations;

    const tz_list = useMemo(
        () => _.map(timeZone_list, ({ key, text, value }) => ({ key, value, text: i18n._(text) })),
        // eslint-disable-next-line
        [current_lng]
    );

    const stationOptions = useMemo(() => {
        if (stations_list) {
            return _.map(stations_list, ({ key, text, value }) => {
                return { key, value, text: i18n._(text) };
            });
        } else {
            return [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n, stations_list]);

    const initialValues = useMemo(() => {
        if (site.isSuccess) {
            return {
                ...default_site,
                ...site.data,
                station: site.data.station === null ? -1 : site.data.station,
                conversions: { ...default_site.conversions, ...site.data.conversions }
            };
        }
        return default_site;
    }, [default_site, site]);

    const rights = checkRights(initialValues, auth.rights, true);

    const can_change = notification.srv_status.db_status === "rw" && _.includes(rights, "can_change");

    const [createSite, create] = useCreateSiteMutation();
    const [updateSite, update] = useUpdateSiteMutation();

    // Create site
    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create site`), toast_options);
            history_app.push(`/sites`);
        }
        if (create.isError) {
            let error = i18n._(t`cannot create site`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create]);

    // Update Site
    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update site`), toast_options);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update site`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    const handleShowDropdown = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    const resetForm = (form) => {
        form.reset();
        form.getRegisteredFields().forEach((field) => {
            form.resetFieldState(field);
        });
    };

    const handleSubmit = async (formData, form) => {
        if (can_change) {
            const data = {
                ...formData,
                station: formData.station === -1 ? null : formData.station
            };

            //process parse string number to number
            _.forOwn(data.conversions, (value, key) => {
                if (key === "currency") {
                    data.conversions[key] = value;
                } else {
                    if (value === null) {
                        data.conversions[key] = null;
                    } else if (typeof value === "number") {
                        data.conversions[key] = value;
                    } else if (typeof value === "string") {
                        const parsedNumber = Number(value?.replace(",", "."));
                        if (!isNaN(parsedNumber)) {
                            data.conversions[key] = parsedNumber;
                        }
                    }
                }
            });

            if (id_site === undefined) {
                await createSite({ org: org.current, data });
            } else {
                const update = await updateSite({ org: org.current, data });
                const error = _.get(update, "error", null);
                if (!error) {
                    resetForm(form);
                }
            }
        }
    };

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target={"/sites"} />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id_site === undefined && <Trans>Add a new site</Trans>}
                            {id_site !== undefined && <Trans>Update site</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                <Grid.Row>
                    {(() => {
                        if (_.some([...err_list, site.isError])) {
                            return (
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            );
                        } else if (_.every(id_site === undefined ? status_list : [...status_list, site.isSuccess])) {
                            return (
                                <Grid.Column width={15}>
                                    <Container>
                                        <Form
                                            keepDirtyOnReinitialize
                                            onSubmit={handleSubmit}
                                            initialValues={initialValues}
                                            render={({ handleSubmit, submitting, pristine, invalid, values, errors }) => {
                                                const allConversionsPrice = _.chain(priceCurrency).map((priceKey) => {
                                                    const conversionObj = values?.conversions?.[priceKey];
                                                    return parseFloat(conversionObj);
                                                });

                                                const allNaN = allConversionsPrice.every(isNaN).value();
                                                const oneNotNan = allConversionsPrice.some(isFinite).value();

                                                const isCurrencyRequired = !allNaN && oneNotNan && !values?.conversions.currency;
                                                const displayCurrencyRequired = !allNaN && oneNotNan;

                                                return (
                                                    <form onSubmit={handleSubmit} className="ui form">
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                    <Field
                                                                        name="name"
                                                                        placeholder={i18n._(t`enter name of site`)}
                                                                        label={i18n._(t`name`)}
                                                                        isRequired={true}
                                                                        component={InputAdapter}
                                                                        parse={identity}
                                                                        validate={(value) => (!value ? <Trans>name is required</Trans> : undefined)}
                                                                        disabled={mode === "view" || !can_change}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                    <Field
                                                                        name="external_id"
                                                                        placeholder={i18n._(t`enter external id of site`)}
                                                                        label={i18n._(t`external_id`)}
                                                                        component={InputAdapter}
                                                                        parse={identity}
                                                                        disabled={mode === "view" || !can_change}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                    <Field
                                                                        name="description"
                                                                        parse={identity}
                                                                        placeholder={i18n._(t`enter description of your site`)}
                                                                        label={i18n._(t`description`)}
                                                                        component={TextAreaAdapter}
                                                                        disabled={mode === "view" || !can_change}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                    <Field
                                                                        name="address"
                                                                        placeholder={i18n._(t`enter address of your site`)}
                                                                        label={i18n._(t`Address`)}
                                                                        component={TextAreaAdapter}
                                                                        parse={identity}
                                                                        disabled={mode === "view" || !can_change}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                    <Field
                                                                        label={i18n._(t`Timezone`)}
                                                                        component={DropDownAdapter}
                                                                        name="timezone"
                                                                        placeholder={i18n._(t`enter timezone of your site`)}
                                                                        compact
                                                                        fluid
                                                                        selection
                                                                        options={tz_list}
                                                                        isRequired={true}
                                                                        validate={(value) => {
                                                                            if (value === -1) {
                                                                                return <Trans>timezone is required</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                        disabled={mode === "view" || !can_change}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                    <Field
                                                                        label={i18n._(t`Association Site - Weather station`)}
                                                                        component={DropDownAdapter}
                                                                        name="station"
                                                                        placeholder={i18n._(t`choose station for your site`)}
                                                                        compact
                                                                        fluid
                                                                        selection
                                                                        options={stationOptions}
                                                                        disabled={mode === "view" || !can_change}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Accordion fluid styled>
                                                                        <Accordion.Title
                                                                            active={activeIndex === 0}
                                                                            index={0}
                                                                            onClick={(e, titleProps) => handleShowDropdown(e, titleProps)}
                                                                        >
                                                                            <Trans>Conversion table </Trans>
                                                                            {activeIndex === 0 ? (
                                                                                <Icon name="caret up" size="small" />
                                                                            ) : (
                                                                                <Icon name="caret down" size="small" />
                                                                            )}
                                                                        </Accordion.Title>
                                                                        <Accordion.Content active={activeIndex === 0}>
                                                                            <Segment padded="very">
                                                                                <Grid columns={2} doubling>
                                                                                    <Grid.Row>
                                                                                        <Grid.Column>
                                                                                            <Field
                                                                                                label={i18n._(t`Currency symbol`)}
                                                                                                name="conversions.currency"
                                                                                                options={dropdownOptions}
                                                                                                value={dropdownOptions}
                                                                                                component={DropDownAdapter}
                                                                                                multiple={true}
                                                                                                defaultValue={""}
                                                                                                disabled={mode === "view" || !can_change}
                                                                                                isRequired={displayCurrencyRequired}
                                                                                            />
                                                                                            {isCurrencyRequired && (
                                                                                                <MessageDisplay
                                                                                                    message={i18n._(
                                                                                                        t`Currency is required if you enter at least one price field`
                                                                                                    )}
                                                                                                    level="info"
                                                                                                    iconName="info circle"
                                                                                                    isLoading={false}
                                                                                                    attached={false}
                                                                                                />
                                                                                            )}
                                                                                            <Field
                                                                                                label={i18n._(
                                                                                                    t`Average price per MWh of energy contract (gas)`
                                                                                                )}
                                                                                                type="text"
                                                                                                parse={identityNull}
                                                                                                inputMode="decimal"
                                                                                                name="conversions.price_gas"
                                                                                                component={InputAdapter}
                                                                                                defaultValue={null}
                                                                                                disabled={mode === "view" || !can_change}
                                                                                                validate={(value) => {
                                                                                                    if (mode === "view" || !can_change) {
                                                                                                        return undefined;
                                                                                                    }
                                                                                                    return validateNumber(value, i18n, false, true);
                                                                                                }}
                                                                                            />
                                                                                            <Field
                                                                                                label={i18n._(
                                                                                                    t`Average price per m³ of gas supply contract`
                                                                                                )}
                                                                                                type="text"
                                                                                                parse={identityNull}
                                                                                                inputMode="decimal"
                                                                                                name="conversions.price_gas_m3"
                                                                                                component={InputAdapter}
                                                                                                defaultValue={null}
                                                                                                disabled={mode === "view" || !can_change}
                                                                                                validate={(value) => {
                                                                                                    if (mode === "view" || !can_change) {
                                                                                                        return undefined;
                                                                                                    }
                                                                                                    return validateNumber(value, i18n, false, true);
                                                                                                }}
                                                                                            />
                                                                                            <Trans></Trans>
                                                                                            <Field
                                                                                                label={i18n._(
                                                                                                    t`Average price per MWh of energy contract (electricity)`
                                                                                                )}
                                                                                                type="text"
                                                                                                parse={identityNull}
                                                                                                inputMode="decimal"
                                                                                                name="conversions.price_elec"
                                                                                                component={InputAdapter}
                                                                                                defaultValue={null}
                                                                                                disabled={mode === "view" || !can_change}
                                                                                                validate={(value) => {
                                                                                                    if (mode === "view" || !can_change) {
                                                                                                        return undefined;
                                                                                                    }
                                                                                                    return validateNumber(value, i18n, false, true);
                                                                                                }}
                                                                                            />
                                                                                            <Field
                                                                                                label={i18n._(
                                                                                                    t`Average price per m³ of fluid supply contract`
                                                                                                )}
                                                                                                type="text"
                                                                                                parse={identityNull}
                                                                                                inputMode="decimal"
                                                                                                name="conversions.price_water"
                                                                                                component={InputAdapter}
                                                                                                defaultValue={null}
                                                                                                disabled={mode === "view" || !can_change}
                                                                                                validate={(value) => {
                                                                                                    if (mode === "view" || !can_change) {
                                                                                                        return undefined;
                                                                                                    }
                                                                                                    return validateNumber(value, i18n, false, true);
                                                                                                }}
                                                                                            />
                                                                                        </Grid.Column>
                                                                                        <Grid.Column>
                                                                                            <Field
                                                                                                label={i18n._(
                                                                                                    t`Electrical emission factor (kgCO2e/kWh)`
                                                                                                )}
                                                                                                type="text"
                                                                                                parse={identityNull}
                                                                                                inputMode="decimal"
                                                                                                name="conversions.co2_elec"
                                                                                                component={InputAdapter}
                                                                                                defaultValue={null}
                                                                                                disabled={mode === "view" || !can_change}
                                                                                                validate={(value) => {
                                                                                                    if (mode === "view" || !can_change) {
                                                                                                        return undefined;
                                                                                                    }
                                                                                                    return validateNumber(value, i18n, false, true);
                                                                                                }}
                                                                                            />
                                                                                            <Field
                                                                                                label={i18n._(t`Gas emission factor (kgCO2e/m³)`)}
                                                                                                type="text"
                                                                                                parse={identityNull}
                                                                                                inputMode="decimal"
                                                                                                name="conversions.co2_m3"
                                                                                                component={InputAdapter}
                                                                                                defaultValue={null}
                                                                                                disabled={mode === "view" || !can_change}
                                                                                                validate={(value) => {
                                                                                                    if (mode === "view" || !can_change) {
                                                                                                        return undefined;
                                                                                                    }
                                                                                                    return validateNumber(value, i18n, false, true);
                                                                                                }}
                                                                                            />
                                                                                            <Field
                                                                                                label={i18n._(t`Gas emission factor (kgCO2e/Nm³)`)}
                                                                                                type="text"
                                                                                                parse={identityNull}
                                                                                                inputMode="decimal"
                                                                                                name="conversions.co2_nm3"
                                                                                                component={InputAdapter}
                                                                                                defaultValue={null}
                                                                                                disabled={mode === "view" || !can_change}
                                                                                                validate={(value) => {
                                                                                                    if (mode === "view" || !can_change) {
                                                                                                        return undefined;
                                                                                                    }
                                                                                                    return validateNumber(value, i18n, false, true);
                                                                                                }}
                                                                                            />
                                                                                            <Field
                                                                                                label={i18n._(t`Gas emission factor (kgCO2e/kWh)`)}
                                                                                                type="text"
                                                                                                parse={identityNull}
                                                                                                inputMode="decimal"
                                                                                                name="conversions.co2_gas"
                                                                                                component={InputAdapter}
                                                                                                defaultValue={null}
                                                                                                disabled={mode === "view" || !can_change}
                                                                                                validate={(value) => {
                                                                                                    if (mode === "view" || !can_change) {
                                                                                                        return undefined;
                                                                                                    }
                                                                                                    return validateNumber(value, i18n, false, true);
                                                                                                }}
                                                                                            />
                                                                                            <Field
                                                                                                label={i18n._(t`Water emission factor (kgCO2e/m³)`)}
                                                                                                type="text"
                                                                                                parse={identityNull}
                                                                                                inputMode="decimal"
                                                                                                name="conversions.co2_water"
                                                                                                component={InputAdapter}
                                                                                                defaultValue={null}
                                                                                                disabled={mode === "view" || !can_change}
                                                                                                validate={(value) => {
                                                                                                    if (mode === "view" || !can_change) {
                                                                                                        return undefined;
                                                                                                    }
                                                                                                    return validateNumber(value, i18n, false, true);
                                                                                                }}
                                                                                            />
                                                                                        </Grid.Column>
                                                                                    </Grid.Row>
                                                                                </Grid>
                                                                            </Segment>
                                                                        </Accordion.Content>
                                                                        {_.includes(["change", "view"], mode) && (
                                                                            <>
                                                                                <Accordion.Title
                                                                                    active={activeIndex === 1}
                                                                                    index={1}
                                                                                    onClick={(e, titleProps) => handleShowDropdown(e, titleProps)}
                                                                                >
                                                                                    <Trans>Areas</Trans>{" "}
                                                                                    {activeIndex === 1 ? (
                                                                                        <Icon name="caret up" size="small" />
                                                                                    ) : (
                                                                                        <Icon name="caret down" size="small" />
                                                                                    )}
                                                                                </Accordion.Title>
                                                                                <Accordion.Content active={activeIndex === 1}>
                                                                                    <Areas
                                                                                        id_site={parseInt(id_site)}
                                                                                        active={activeIndex === 1}
                                                                                        can_change={can_change}
                                                                                    />
                                                                                </Accordion.Content>
                                                                                <Accordion.Title
                                                                                    active={activeIndex === 2}
                                                                                    index={2}
                                                                                    onClick={(e, titleProps) => handleShowDropdown(e, titleProps)}
                                                                                >
                                                                                    <Trans>Calendars management</Trans>{" "}
                                                                                    {activeIndex === 2 ? (
                                                                                        <Icon name="caret up" size="small" />
                                                                                    ) : (
                                                                                        <Icon name="caret down" size="small" />
                                                                                    )}
                                                                                </Accordion.Title>
                                                                                <Accordion.Content active={activeIndex === 2}>
                                                                                    <SiteCalendar
                                                                                        id_site={parseInt(id_site)}
                                                                                        can_change={can_change}
                                                                                        active={activeIndex === 2}
                                                                                        org={org}
                                                                                    />
                                                                                </Accordion.Content>
                                                                            </>
                                                                        )}
                                                                    </Accordion>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                        <Divider />
                                                        {(mode === "change" || id_site === undefined) && can_change && (
                                                            <Button
                                                                style={{ marginRight: "20px" }}
                                                                positive
                                                                type="submit"
                                                                content={i18n._(t`validate`)}
                                                                disabled={submitting || pristine || invalid || isCurrencyRequired}
                                                            />
                                                        )}
                                                    </form>
                                                );
                                            }}
                                        />
                                    </Container>
                                </Grid.Column>
                            );
                        } else {
                            return (
                                <Grid.Column width={15}>
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                                </Grid.Column>
                            );
                        }
                    })()}
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default Site;
