import React, { useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Grid, Segment, Header, Button, Dropdown, Popup, Icon, Label } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { checkRights } from "modules/auth/utils";
import { typeImportOptions } from "../utils";
import history_app from "history_app";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetProductionDatasQuery, useGetImportedDataSetsQuery } from "../importedDataSetsService";
import { setProductionDataPage, setProductionDataItemsPerPage } from "../importedDataSetsSlice";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import TableEnhanced from "modules/common/components/TableEnhanced";
import DeleteProductionData from "./DeleteProductionData";
import ProductionDataModalForm from "./ProductionDataModalForm";
import UploadFileModalForm from "./UploadFileModalForm";
import TimePanel from "modules/common/components/TimePanel";

const ProductionDatas = () => {
    const dispatch = useDispatch();
    const { dataset_id: url_id } = useParams();
    const dataset_id = parseInt(url_id);
    const { org, importedDataSets, notification, auth } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const theme = useSelector((state) => state.common.theme);

    const default_time = useMemo(() => {
        const now = moment();
        return {
            start: now.clone().subtract(3, "months"),
            end: now.clone()
        };
    }, []);

    const [time, setTime] = useState(default_time);
    // Modal management
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(null);
    const [openDelete, setOpenDelete] = useState(null);
    const [openUpload, setOpenUpload] = useState(null);

    const renderImportType = useCallback(
        (type_import) => {
            const import_type = _.find(typeImportOptions, { value: type_import });
            if (import_type) {
                return i18n._(import_type?.text);
            }
            return "-";
        },
        // eslint-disable-next-line
        [current_lng]
    );

    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const getImportedDataSets = useGetImportedDataSetsQuery({ org: org.current }, { skip: !org.current });
    const { myDataSet, renderLabels, can_change, can_delete } = useMemo(() => {
        if (getImportedDataSets.isSuccess) {
            const dataset = getImportedDataSets.data?.find((item) => item.id === dataset_id);
            if (dataset) {
                const zone = _.find(zones.data, { id: dataset?.zone });
                const site = _.find(sites.data, { id: zone?.site_id });
                const rights = checkRights(site, auth.rights, true);
                const can_change = notification.srv_status.db_status === "rw" && _.includes(rights, "can_change");
                const can_delete = notification.srv_status.db_status === "rw" && _.includes(rights, "can_delete");

                return {
                    myDataSet: dataset,
                    renderLabels: (
                        <Label.Group>
                            <Label
                                color="teal"
                                tag={theme === "old" ? true : false}
                                circular={theme === "old" ? false : true}
                                basic={theme === "old" ? false : true}
                            >
                                {dataset?.zone_name ?? "-"}
                            </Label>
                            <Label
                                color="violet"
                                tag={theme === "old" ? true : false}
                                circular={theme === "old" ? false : true}
                                basic={theme === "old" ? false : true}
                            >
                                {renderImportType(dataset.type_import)}
                            </Label>
                        </Label.Group>
                    ),
                    can_change,
                    can_delete
                };
            }
        }
        return { myDataSet: null, renderLabels: null, can_change: false, can_delete: false };
    }, [dataset_id, getImportedDataSets, zones, sites, renderImportType, auth.rights, notification, theme]);

    const getProductionDatas = useGetProductionDatasQuery(
        {
            org: org.current,
            id: dataset_id,
            start: time.start.format("YYYY-MM-DD"),
            end: time.end.format("YYYY-MM-DD")
        },
        { skip: !org.current || getImportedDataSets.isError || !_.isFinite(dataset_id) }
    );

    const err_list = [getImportedDataSets.isError, getProductionDatas.isError];
    const status_list = [getImportedDataSets.isSuccess, getProductionDatas.isSuccess];

    const importedDatasets_options = useMemo(() => {
        if (getImportedDataSets.isSuccess) {
            return _.chain(getImportedDataSets?.data)
                .map((dataset) => ({ key: dataset.id, text: dataset.name, value: dataset.id }))
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
    }, [getImportedDataSets]);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "day", label: i18n._(t`day`), textAlign: "left" },
        { id: "value", label: `${renderImportType(myDataSet?.type_import)}`, textAlign: "left" },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }
    ];

    const productionDatas_list = useMemo(() => {
        if (getProductionDatas.isSuccess) {
            return _.chain(getProductionDatas.data)
                .reduce((res, item) => {
                    const customStyle = { cursor: "default", whiteSpace: "nowrap" };
                    const tmst = moment(item.day, moment.ISO_8601, true);

                    res.push({
                        ...item,
                        id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                        day: {
                            render: <span style={customStyle}>{tmst.isValid() ? tmst.locale(current_lng).format("LL") : "-"}</span>,
                            value: tmst.isValid() ? tmst : null,
                            textAlign: "left",
                            datatype: null
                        },
                        value: {
                            render: <span style={customStyle}>{item?.v ?? "-"}</span>,
                            value: item?.v ?? "-",
                            textAlign: "left",
                            datatype: "number"
                        },

                        actions: {
                            render: (
                                <Button.Group>
                                    <Popup
                                        trigger={
                                            <Button
                                                type="button"
                                                icon="edit"
                                                onClick={async (e) => {
                                                    if (can_change) {
                                                        setOpenEdit(item);
                                                    }
                                                }}
                                                disabled={!can_change}
                                            />
                                        }
                                    >
                                        <Popup.Content>
                                            <Trans>Edit imported data</Trans>
                                        </Popup.Content>
                                    </Popup>
                                    <Popup
                                        trigger={
                                            <Button
                                                type="button"
                                                icon="delete"
                                                onClick={async (e) => {
                                                    if (can_delete) {
                                                        setOpenDelete(item);
                                                    }
                                                }}
                                                disabled={!can_delete}
                                            />
                                        }
                                    >
                                        <Popup.Content>
                                            <Trans>Delete imported data</Trans>
                                        </Popup.Content>
                                    </Popup>
                                </Button.Group>
                            ),
                            value: null,
                            textAlign: "right",
                            datatype: null
                        }
                    });
                    return res;
                }, [])
                .value();
        }
        return [];
    }, [getProductionDatas, current_lng, can_change, can_delete]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                {openAdd && <ProductionDataModalForm dataset_id={dataset_id} setOpen={setOpenAdd} />}
                {openEdit && <ProductionDataModalForm item={openEdit} dataset_id={dataset_id} setOpen={setOpenEdit} />}
                {openDelete && <DeleteProductionData item={openDelete} dataset_id={dataset_id} setOpen={setOpenDelete} />}
                {openUpload && <UploadFileModalForm item={openUpload} dataset_id={dataset_id} setOpen={setOpenUpload} />}
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target="/importeddatasets" />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Imported datas</Trans>
                            {myDataSet?.name ? `: ${myDataSet?.name}` : ""}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <TimePanel
                            hide_plage={true}
                            time={time}
                            timeFormat={false}
                            action={({ start, end }) => {
                                setTime({ start: moment(start), end: moment(end) });
                            }}
                            date_limit={null}
                        />
                    </Grid.Column>
                </Grid.Row>
                {(() => {
                    if (_.some(err_list)) {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    } else if (_.every(status_list)) {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <Grid celled>
                                        <Grid.Row>
                                            <Grid.Column mobile={16} tablet={3} computer={3}>
                                                <Dropdown
                                                    fluid
                                                    options={importedDatasets_options}
                                                    placeholder={i18n._(t`select a data set`)}
                                                    selection
                                                    search
                                                    onChange={(e, { value }) => {
                                                        history_app.push(`/importeddatasets/${value}/productiondata`);
                                                    }}
                                                    value={dataset_id}
                                                />
                                            </Grid.Column>
                                            <Grid.Column style={{ paddingTop: ".9rem" }} mobile={16} computer={13}>
                                                {renderLabels}
                                            </Grid.Column>
                                        </Grid.Row>

                                        {can_change && (
                                            <Grid.Column width={16}>
                                                <Button onClick={() => setOpenAdd(true)} type="button" icon labelPosition="left">
                                                    <Icon name="add" />
                                                    <Trans>Add imported data</Trans>
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setOpenUpload(myDataSet);
                                                    }}
                                                    type="button"
                                                    icon
                                                    labelPosition="left"
                                                >
                                                    <Icon name="upload" />
                                                    <Trans>Upload data from file</Trans>
                                                </Button>
                                            </Grid.Column>
                                        )}

                                        {/* Table  */}
                                        <TableEnhanced
                                            headCells={headCells}
                                            rows={productionDatas_list}
                                            order="desc"
                                            orderBy="day"
                                            page={importedDataSets.productionData.pagination.page}
                                            rowsPerPage={importedDataSets.productionData.pagination.itemsPerPage}
                                            setPage={(page) => {
                                                dispatch(setProductionDataPage(page));
                                            }}
                                            setItemsPerPage={(items) => {
                                                dispatch(setProductionDataItemsPerPage(items));
                                            }}
                                            textItemPerPages={i18n._(t`items per page`)}
                                        />
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        );
                    } else {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    }
                })()}
            </Grid>
        </Segment>
    );
};

export default ProductionDatas;
