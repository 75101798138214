import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import moment from "moment";
import _ from "lodash";
import { Grid, Header, Icon, Label, Popup, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { getOperatingMap, getOperatingRaw, setTime } from "modules/overview/overviewSlice";
import { dateRangeOptions } from "modules/time/utils";

import TimePanel from "modules/common/components/TimePanel";
import MessageDisplay from "modules/common/components/MessageDisplay";
import GraphicOperatingMap from "../graphic/GraphicOperatingMap";
import GraphicOperatingRaw from "../graphic/GraphicOperatingRaw";

const TabPredictSupplyAggregate = (props) => {
    const { equipment, default_time } = props;
    const machine = _.get(equipment, "machine");
    const ratedFrequency = _.chain(machine).get("components").find({ comp_type: 2 }).get("description.ratedFrequency").value() || 50;
    const ratedCurrent = _.chain(machine).get("components").find({ comp_type: 2 }).get("description.ratedCurrent").value() || null;

    const dispatch = useDispatch();
    const { org } = useSelector((state) => state);
    const [isMore15days, setIsMore15days] = useState(false);
    const { tabpredictsupplyaggregate, predicttime, tabpredictsupplydetail } = useSelector((state) => state.overview);

    const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["24h", "48h", "72h"], item.value));
    const rangeTime = predicttime.time
        ? {
              start: moment(predicttime.time.start),
              end: moment(predicttime.time.end),
              plage: predicttime.time.plage
          }
        : default_time;

    useEffect(() => {
        (async () => {
            if (!_.isUndefined(machine)) {
                if (predicttime.time) {
                    const { start, end } = predicttime.time;
                    if (moment(end).diff(moment(start), "d") >= 15) {
                        setIsMore15days(true);
                        dispatch(
                            getOperatingMap({
                                org: org.current,
                                machine,
                                start: moment(start).format("YYYY-MM-DD"),
                                end: moment(end).format("YYYY-MM-DD"),
                                tab: "tabpredictsupplyaggregate",
                                type: "frequency"
                            })
                        );
                        dispatch(
                            getOperatingMap({
                                org: org.current,
                                machine,
                                start: moment(start).format("YYYY-MM-DD"),
                                end: moment(end).format("YYYY-MM-DD"),
                                tab: "tabpredictsupplyaggregate",
                                type: "current"
                            })
                        );
                    } else {
                        setIsMore15days(false);
                        dispatch(getOperatingRaw({ org: org.current, machine, start, end, tab: "tabpredictsupplydetail", type: "frequency" }));
                        dispatch(getOperatingRaw({ org: org.current, machine, start, end, tab: "tabpredictsupplydetail", type: "current" }));
                    }
                } else {
                    //default display we use 'detail'
                    dispatch(
                        getOperatingRaw({
                            org: org.current,
                            machine,
                            start: default_time.start.toISOString(),
                            end: default_time.end.toISOString(),
                            tab: "tabpredictsupplydetail",
                            type: "frequency"
                        })
                    );
                    dispatch(
                        getOperatingRaw({
                            org: org.current,
                            machine,
                            start: default_time.start.toISOString(),
                            end: default_time.end.toISOString(),
                            tab: "tabpredictsupplydetail",
                            type: "current"
                        })
                    );
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [predicttime.time]);

    return (
        <Grid id="pwaMain" verticalAlign="middle" centered>
            <Popup
                trigger={
                    <Label corner>
                        <Icon name="question circle" />
                    </Label>
                }
                position="bottom left"
            >
                <Popup.Content>
                    <p>
                        <Trans>Display electrical measurements, aggregated as a heatmap when a period of more than 15 days is selected.</Trans>
                    </p>
                    <p>
                        <Trans>
                            <p>supply detail helper</p>
                            <p>supply detail helper 2</p>
                        </Trans>
                    </p>
                </Popup.Content>
            </Popup>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        rounded={60}
                        action={({ start, end, plage }) => {
                            dispatch(setTime({ start, end, plage, tab: "predicttime" }));
                        }}
                        date_limit={moment()}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={8} textAlign="center" className="pwaGraph">
                    <Header as={Segment} attached="top" block textAlign="center">
                        <Trans>supply freq</Trans>
                    </Header>
                    {_.isUndefined(machine) && (
                        <MessageDisplay message={i18n._(t`machine unknown`)} level="warning" iconName="info circle" isLoading={false} />
                    )}
                    {(tabpredictsupplyaggregate.frequency.status === "loading" || tabpredictsupplydetail.frequency.status === "loading") && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {(tabpredictsupplyaggregate.frequency.status === "failed" || tabpredictsupplydetail.frequency.status === "failed") && (
                        <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                    )}
                    {isMore15days === true && tabpredictsupplyaggregate.frequency.status === "succeeded" && (
                        <GraphicOperatingMap
                            type="frequency"
                            time={rangeTime}
                            clr_min={"#ffe3e3"}
                            clr_max={"#660000"}
                            threshold={ratedFrequency}
                            data={_.get(tabpredictsupplyaggregate, "frequency.data.data")}
                            titleY={`${i18n._(t`frequency`)} (Hz)`}
                        />
                    )}
                    {isMore15days === false && tabpredictsupplydetail.frequency.status === "succeeded" && (
                        <GraphicOperatingRaw
                            type="frequency"
                            time={rangeTime}
                            threshold={ratedFrequency}
                            data={_.get(tabpredictsupplydetail, "frequency.data")}
                            titleY={`${i18n._(t`frequency`)} (Hz)`}
                        />
                    )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8} textAlign="center" className="pwaGraph">
                    <Header as={Segment} attached="top" block textAlign="center">
                        <Trans>current</Trans>
                    </Header>
                    {_.isUndefined(machine) && (
                        <MessageDisplay message={i18n._(t`machine unknown`)} level="warning" iconName="info circle" isLoading={false} />
                    )}
                    {(tabpredictsupplyaggregate.current.status === "loading" || tabpredictsupplydetail.current.status === "loading") && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {(tabpredictsupplyaggregate.current.status === "failed" || tabpredictsupplydetail.current.status === "failed") && (
                        <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                    )}
                    {isMore15days === true && tabpredictsupplyaggregate.current.status === "succeeded" && (
                        <GraphicOperatingMap
                            type="current"
                            time={rangeTime}
                            clr_min={"#e3f1ff"}
                            clr_max={"#003366"}
                            threshold={ratedCurrent}
                            data={_.get(tabpredictsupplyaggregate, "current.data.data")}
                            titleY={`${i18n._(t`rms current`)} (A)`}
                        />
                    )}
                    {isMore15days === false && tabpredictsupplydetail.current.status === "succeeded" && (
                        <GraphicOperatingRaw
                            type="current"
                            time={rangeTime}
                            threshold={ratedCurrent}
                            data={_.get(tabpredictsupplydetail, "current.data")}
                            titleY={`${i18n._(t`rms current`)} (A)`}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default TabPredictSupplyAggregate;
