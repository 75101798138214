import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Button, Grid, Header, Icon, Modal, Segment, Table } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";

import { InputAdapter } from "modules/common/components/form";
import { nextStep, previousStep, setPumpInfo } from "../kinematicSlice";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { reparseNumber, validateNumber } from "modules/common/utils";
import Palette from "modules/common/components/graphic/Colors";

export const KinematicPumpForm = (props) => {
    const { machine, mode } = props;
    const dispatch = useDispatch();
    const { component, kinematic } = useSelector((state) => state);
    const [open, setOpen] = useState(false);

    //Get motor speed for pump speed default value
    const motor_speed = _.get(kinematic, "kinematic.motor.ratedSpeed", null);

    const pump_component = _.chain(component.components)
        .filter({ machine: _.get(machine, "id") })
        .find({ comp_type: 6 })
        .value();

    const description = _.get(pump_component, "description", null);
    const Density = _.get(description, "Density", 1);
    const ratedSpeed = _.get(description, "ratedSpeed", motor_speed); //get motor ratedSpeed
    const machine_type = _.get(machine, "machine_type", null);

    //Transform component values for semantic ui usage
    const transform_desc = {
        Density,
        ratedSpeed,
        values: _.range(5).map((idx) => {
            let descr = "";
            if (idx === 0) descr = i18n._(t`valeur débit nul`);
            else if (idx === 2) descr = i18n._(t`valeur débit au BEP`);
            else if (idx === 4) descr = i18n._(t`valeur débit max`);
            return {
                description: descr,
                Qi: _.get(description, `Qi[${idx}]`, null),
                Hi: _.get(description, `Hi[${idx}]`, null),
                Pi: _.get(description, `Pi[${idx}]`, null)
            };
        })
    };

    const onSubmitForm = async (data) => {
        const reParsedData = {
            ...data,
            Density: reparseNumber(data?.Density),
            ratedSpeed: reparseNumber(data?.ratedSpeed),
            values: _.map(data?.values, (line) => {
                return _.mapValues(line, (item, key) => {
                    if (key === "description") return item;
                    return reparseNumber(item);
                });
            })
        };
        await dispatch(setPumpInfo(reParsedData));
        await dispatch(nextStep());
    };

    const pump_info = kinematic.kinematic.pump ? kinematic.kinematic.pump : transform_desc;

    const initialValues = useMemo(() => {
        return {
            ...pump_info
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form
                keepDirtyOnReinitialize
                onSubmit={onSubmitForm}
                mutators={{ ...arrayMutators }}
                initialValues={initialValues}
                render={({ handleSubmit, invalid, values, form, errors }) => {
                    const wrap_info = _.chain(values)
                        .get("values", [])
                        .reduce((res, record) => {
                            res.push(record.Qi);
                            res.push(record.Hi);
                            res.push(record.Pi);
                            return res;
                        }, []);

                    const all_empty_info = wrap_info
                        .every((item) => {
                            return item === null;
                        })
                        .value();

                    const some_empty_info = wrap_info
                        .some((item) => {
                            return item === null;
                        })
                        .value();

                    const list_Qi = _.chain(values)
                        .get("values", [])
                        .map((item) => {
                            const qi = _.get(item, "Qi", null);
                            if (qi === null) return null;
                            return parseFloat(qi);
                        })
                        .filter((item) => item !== null && _.isFinite(item))
                        .value();
                    const strict_increase = _.every(list_Qi, (value, index, array) => {
                        if (index > 0) {
                            return value > array[index - 1];
                        }
                        return true;
                    });
                    const list_Pi = _.chain(values)
                        .get("values", [])
                        .map((item) => {
                            const pi_val = _.get(item, "Pi", null);
                            if (pi_val === null) return null;
                            return parseFloat(pi_val);
                        })
                        .value();

                    const all_pi_null = _.every(list_Pi, (item) => item === null);

                    const strict_increase_pi = _.every(_.take(list_Pi, 4), (value, index, array) => {
                        if (index > 0) {
                            return value > array[index - 1];
                        }
                        return true;
                    });

                    const last_pi_decrease = list_Pi?.[4] === null || list_Pi?.[3] === null ? false : list_Pi?.[4] < list_Pi?.[3];
                    const last_pi_equal = list_Pi?.[4] === null || list_Pi?.[3] === null ? false : list_Pi?.[4] === list_Pi?.[3];

                    return (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Header as={"h3"} attached="top" block textAlign="center">
                                <Trans>pump info</Trans>
                            </Header>
                            <Segment attached>
                                <Field
                                    name="ratedSpeed"
                                    placeholder={i18n._(t`enter speed of pump`)}
                                    label={i18n._(t`speed_pump`)}
                                    component={InputAdapter}
                                    isRequired={true}
                                    unit={"rpm"}
                                    parse={identityNull}
                                    inputMode="decimal"
                                    defaultValue={motor_speed}
                                    validate={(value) => {
                                        if (mode === "view") return undefined;
                                        return validateNumber(value, i18n, false, false);
                                    }}
                                    disabled={mode === "view"}
                                />
                                <Field
                                    name="Density"
                                    placeholder={i18n._(t`enter fluid density`)}
                                    label={i18n._(t`density_pump`)}
                                    component={InputAdapter}
                                    isRequired={true}
                                    parse={identityNull}
                                    inputMode="decimal"
                                    defaultValue={1}
                                    validate={(value) => {
                                        if (mode === "view") return undefined;
                                        return validateNumber(value, i18n, false, false);
                                    }}
                                    disabled={mode === "view"}
                                />
                                {machine_type === 7 && ( //Only centrifugal_pump
                                    <Table compact>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={{ color: "white", backgroundColor: Palette.named.blue }}>
                                                    Description
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={{ color: "white", backgroundColor: Palette.named.blue }}>
                                                    <Trans>flow rate</Trans> (m3/h)
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={{ color: "white", backgroundColor: Palette.named.blue }}>
                                                    <Trans>head</Trans> (m)
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={{ color: "white", backgroundColor: Palette.named.blue }}>
                                                    <Trans>meca_power_motor</Trans> (kW)
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {mode === "change" && strict_increase === false && (
                                                <Table.Row>
                                                    <Table.Cell colSpan={4}>
                                                        <MessageDisplay
                                                            message={i18n._(t`Qi values must be strictly increasing`)}
                                                            level="error"
                                                            iconName="warning circle"
                                                            isLoading={false}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                            {mode === "change" && all_pi_null === false && strict_increase_pi === false && (
                                                <Table.Row>
                                                    <Table.Cell colSpan={4}>
                                                        <MessageDisplay
                                                            message={i18n._(t`Pi values must be strictly increasing`)}
                                                            level="error"
                                                            iconName="warning circle"
                                                            isLoading={false}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                            {mode === "change" && all_pi_null === false && last_pi_decrease === true && (
                                                <Table.Row>
                                                    <Table.Cell colSpan={4}>
                                                        <MessageDisplay
                                                            message={i18n._(
                                                                t`If the last mechanical power value is decreasing then the point will be ignored`
                                                            )}
                                                            level="info"
                                                            iconName="info circle"
                                                            isLoading={false}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                            {mode === "change" && all_pi_null === false && last_pi_equal === true && (
                                                <Table.Row>
                                                    <Table.Cell colSpan={4}>
                                                        <MessageDisplay
                                                            message={i18n._(t`The last mechanical power value cannot be equal to the previous value`)}
                                                            level="error"
                                                            iconName="warning circle"
                                                            isLoading={false}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                            <FieldArray name="values">
                                                {(value_field) => {
                                                    return value_field.fields.map((value_name, value_index) => {
                                                        const current_value = value_field.fields.value[value_index];
                                                        return (
                                                            <Table.Row key={value_name}>
                                                                <Table.Cell>
                                                                    <Trans>{current_value.description}</Trans>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <Field
                                                                        name={`${value_name}.Qi`} //m3/h
                                                                        placeholder={i18n._(t`qi placeholder`)}
                                                                        component={InputAdapter}
                                                                        parse={identityNull}
                                                                        inputMode="decimal"
                                                                        defaultValue={null}
                                                                        validate={(value) => {
                                                                            if (mode === "view") return undefined;
                                                                            const qiValidate = validateNumber(value, i18n, false, true);
                                                                            if (qiValidate !== undefined) {
                                                                                return qiValidate;
                                                                            }
                                                                            if (value_index === 0 && value !== null && reparseNumber(value) !== 0) {
                                                                                return <Trans>First value needs to be 0</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                        disabled={mode === "view"}
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <Field
                                                                        name={`${value_name}.Hi`} //m
                                                                        placeholder={i18n._(t`hi placeholder`)}
                                                                        component={InputAdapter}
                                                                        parse={identityNull}
                                                                        inputMode="decimal"
                                                                        defaultValue={null}
                                                                        validate={(value) => {
                                                                            if (mode === "view") return undefined;
                                                                            return validateNumber(value, i18n, false, true);
                                                                        }}
                                                                        disabled={mode === "view"}
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <Field
                                                                        name={`${value_name}.Pi`} //kW
                                                                        placeholder={i18n._(t`pi placeholder`)}
                                                                        component={InputAdapter}
                                                                        parse={identityNull}
                                                                        inputMode="decimal"
                                                                        defaultValue={null}
                                                                        validate={(value, allValues) => {
                                                                            if (mode === "view") return undefined;
                                                                            const piValidate = validateNumber(value, i18n, false, true);
                                                                            if (piValidate !== undefined) {
                                                                                return piValidate;
                                                                            }
                                                                            //Process all_empty_info based on allValues form local
                                                                            const wrap_info = _.chain(allValues)
                                                                                .get("values", [])
                                                                                .reduce((res, record) => {
                                                                                    res.push(record.Qi);
                                                                                    res.push(record.Hi);
                                                                                    res.push(record.Pi);
                                                                                    return res;
                                                                                }, []);
                                                                            const all_empty_info = wrap_info
                                                                                .every((item) => {
                                                                                    return item === null;
                                                                                })
                                                                                .value();
                                                                            if (reparseNumber(value) <= 0 && all_empty_info === false) {
                                                                                return <Trans>value strictly positive</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                        disabled={mode === "view"}
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        );
                                                    });
                                                }}
                                            </FieldArray>
                                        </Table.Body>
                                    </Table>
                                )}
                            </Segment>
                            <Segment attached>
                                <Grid columns={2}>
                                    <Grid.Column textAlign="left">
                                        <Button
                                            type="button"
                                            icon="left arrow"
                                            compact
                                            labelPosition="left"
                                            content={i18n._(t`previous`)}
                                            onClick={(e) => {
                                                dispatch(previousStep());
                                            }}
                                        />
                                    </Grid.Column>
                                    {mode === "change" && (
                                        <Grid.Column textAlign="right">
                                            {!all_empty_info && (
                                                <Button
                                                    type="submit"
                                                    disabled={
                                                        invalid ||
                                                        _.isUndefined(machine) ||
                                                        strict_increase === false ||
                                                        strict_increase_pi === false ||
                                                        last_pi_equal === true ||
                                                        some_empty_info
                                                    }
                                                    icon="right arrow"
                                                    labelPosition="right"
                                                    content={i18n._(t`next`)}
                                                    compact
                                                />
                                            )}
                                            {all_empty_info && (
                                                <Modal
                                                    centered={false}
                                                    onClose={() => setOpen(false)}
                                                    onOpen={() => setOpen(true)}
                                                    open={open}
                                                    trigger={
                                                        <Button
                                                            type="button"
                                                            disabled={invalid || _.isUndefined(machine)}
                                                            icon="right arrow"
                                                            labelPosition="right"
                                                            content={i18n._(t`next`)}
                                                            compact
                                                        />
                                                    }
                                                >
                                                    <Modal.Header>
                                                        <Trans>Pump info not set</Trans>
                                                    </Modal.Header>
                                                    <Modal.Content>
                                                        <Trans>
                                                            if you do not enter the data of your pump then you will have limited information on your
                                                            machine.
                                                        </Trans>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button type="button" negative onClick={() => setOpen(false)}>
                                                            <Trans>cancel</Trans>
                                                        </Button>
                                                        <Button
                                                            labelPosition="right"
                                                            icon
                                                            positive
                                                            type="button"
                                                            onClick={() => {
                                                                setOpen(false);
                                                                form.submit();
                                                            }}
                                                        >
                                                            <Trans>validate</Trans>
                                                            <Icon name="check" inverted />
                                                        </Button>
                                                    </Modal.Actions>
                                                </Modal>
                                            )}
                                        </Grid.Column>
                                    )}
                                </Grid>
                            </Segment>
                        </form>
                    );
                }}
            />
        </>
    );
};

export default KinematicPumpForm;
